import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import cla from '../assets/images/cla-persona.png'
import deb from '../assets/images/debHome.png'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Relationship-Based Business Strategy &amp; Design | Mike Weisert | mikew.design</title>
            <meta name="description" content="Redefining the Fleet Management Experience" />            
        </Helmet>

        <div id="main" className="alt">
            <section id="banner" className="major oni">
                    <div className="inner">
                    <div className="content">
                        <header className="major">
                            <h1>Relationship-Based Business Strategy &amp; Design</h1>
                            <div className="row">
                                <div className="12u 12u$(small)">As a Senior UX Strategist at <a href="https://www.onenorth.com" target="_blank">One North Interactive</a>, I designed targeted client experiences and crafted marketing strategy for the largest legal and financial services firms.
                                <div className="empDates">2012-2015</div>
                                </div>
                            </div>  
                        </header>
                    </div>
                </div>
            </section>
            <section id="glance">
            <div className="inner">
                    <div className="content">
                    <h2>Overview</h2> 
                        <div className="grid-wrapper">
                        
                            <div className="col-8">
                                <h3>Achievements</h3>
                                <ul>
                                    <li>UX lead on award winning websites for large firms such as <span className="emph">Debevoise &amp; Plimpton, Locke Lord,</span> and  <span className="emph">Baker Botts</span></li>
                                    <li>Received Sitecore Web Marketer certification, leading to client strategy guidance on personalization on content-driven websites</li>
                                    <li>Achieved <span className="emph">Google Analytics certification</span> and used this knowledge to drive sales by leveraging analytics data on client sites</li>
                                    <li>Co-presenter at the Bay Area Legal Marketers Assoc. with company CEO John Simpson on the topic of content marketing</li>
                                    <li>Presenter at the annual client summit on the topic of Google Analytics</li>
                                    <li>Advanced the UX team methodologies from static images to prototype-driven design</li>
                                    <li>Authored detailed functional specification documents used to align the client and our development team</li>
                                </ul>
                            </div>
                        
                            <div className="col-4" id="quick">
                                <h3>About One North</h3>
                                <ul>
                                    <li>Originally part of Thomson Reuters, One North is an agency focused on creating web experience for relationship-based businesses (primarily Legal and Financial Services)</li>
                                    <li>Nearly half of the AmLaw 100 law firm websites are created by One North</li>
                                    <li>Web agency combining strategy, technology and design to create compelling, content-driven experiences</li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
            </section>
            <section id="three">
                <div className="inner">
                    <div className="content">
                        <h3>UX Strategist</h3>
                            <p>
                            As a strategist with One North, I designed experiences based around content marketing to legal General Counsel, as well as C-Suite buyers from large corporations.  It was common for me to present and coordinate design discussions with large groups of legal marketers and key stakeholders, often including managing partners.  By collaborating with the in-house marketing teams at various firms, my team and I were able to craft experiences that best reflected the goals of firm while providing value to their customers.
                            </p>

                        <h3>Case Study: Debevoise &amp; Plimpton (Debevoise)</h3>
                            <p>
                                <a href="https://www.debevoise.com/" target="_blank">Debevoise</a> is a New York based firm focused on large mergers &amp; acquisitions, while also representing large corporations in litigation matters.  When meeting with the leadership team, they made it clear that the website was to showcase a firm that was 'constantly in the news'.  Highlights of the site include:
                                <ul>
                                    <li>Homepage focused on constantly changing content that highlights experience.  Each article exposes the detail of the story without taking the user away from the page.</li>
                                    <li>An experience section which allows the firm to curate prominent work, while also telling a story of the practices, industries and areas of the world the firm is prevalent in.</li>
                                    <li>Lawyer information pages that minimize low-value overview text, while promoting the key experience of the individual.</li>
                                </ul>
                            </p>
                            <p>
                            <div className="col-6"><span className="image fit"><img src={deb} alt="Debevoise Homepage" /></span></div>
                                Experience focused hompepage.
                            </p>
                        <h3>Case Study: CliftonLarsonAllen (CLA)</h3>
                            <p>
                            CLA is a large financial services firm based out of Minneapolis. As the lead UX designer for the project, I partnered with the marketing team to conduct client research to inform website design and Sitecore personalization strategy.  To uncover these insights we took the following approach:
                                <ul>
                                    <li>At the kickoff, we identified various client audiences and formed our hypothesis on how these users leverage digital content</li>
                                    <li>We recruited approximately 10 clients and interviewed them as it related to their personal habit, research approaches and impressions of the firm</li>
                                    <li>The outcomes of the discussions allowed us to create robust personas that were backed by actual quotes and insight</li>
                                    <li>Based on the outcomes, we were able to create a strategy approach for site personalization on the Sitecore platform</li>
                                </ul>
                            </p>
                            <p>
                            <div className="col-6"><span className="image fit"><img src={cla} alt="Personas for CLA" /></span></div>
                            Persona based on Research for CLA
                            </p>
                    </div>
                </div>
            
            </section>
            <section id="cta">
                <div className="inner">
                    <ul className="actions fit">
                        <li><Link to="/ci" className="button fit">Back</Link></li>
                        <li><Link to="/audio-visual" className="button fit special">Next</Link></li>
                    </ul>
                </div>        
            </section>
        </div>

    </Layout>
)

export default Generic